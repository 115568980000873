import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import SideMenu from './SideMenu';
import DesignToDoc from './imagetotext'; // Assuming this is a component
import TextToDoc from './TextToDoc'; // Assuming this is a component
import WorkBoard from './WorkBoard'; // Assuming this is a component
import Pricing from './Pricing'; // Assuming this is a component
import Settings from './Settings'; // Assuming this is a component
import TopPage from './TopPage';
import './App.css';
import SearchResultsPage from './SearchResultsPage ';
import Home from './home';
import fetchAndStoreOrders from './fetchOrders'; // Adjust the path as necessary
import { checkStatusExpiryDate, updateGenerateAmount, updateGenerateRefreshDate } from './firebase'; // Ensure this is the correct path
import Auth from './Auth';
import Contact from './Contact';
import NotFound from './NotFound';

const AuthStateHandler = () => {
  const navigate = useNavigate();
  const [isInitialLogin, setIsInitialLogin] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (isInitialLogin) {
        // Check if the current route is '/auth' before navigating
        if (window.location.pathname === '/auth') {
          navigate(user ? '/design-to-doc' : '/', { replace: true });
        }
        setIsInitialLogin(false); // Set to false after initial login
      }
    });
  
    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [navigate, isInitialLogin]);
  

  return null; // This component does not render anything
};


const App = () => {

  return (
    <Router>
      <AuthStateHandler />
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/notfound" element={<NotFound />} />
        </Routes>
        <SideMenu />
        <div className='mainPageArea'>
          <TopPage />
          <Routes>
            <Route path="/design-to-doc" element={<DesignToDoc />} />
            <Route path="/text-to-doc" element={<TextToDoc />} />
            <Route path="/work-board" element={<WorkBoard />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/search" element={<SearchResultsPage />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
