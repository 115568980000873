import React, { useEffect, useState } from 'react';
import './home.css'; // Assuming you have a CSS file for styling
import Logo from './images/logo.svg'
import LogoM from './images/logo-m.svg'
import MainPageImage from './images/mainPageImage.png';
import { IoIosPlayCircle, IoMdShare  } from "react-icons/io";
import FAQ from './Faq';
import FAQMain from './FaqMain';
import Rocket from './images/rocket.png'
import Free from './images/free.png'
import Design from './images/design.png'
import Text from './images/text.png'
import { signInWithGoogle } from './firebase';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import animationData from './images/ImageAnimation.json';
import {
    TwitterShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterIcon,
    FacebookIcon,
    LinkedinIcon,
} from 'react-share';

window.ml = function () { };

const Home = () => {
    const [showVideoPopup, setShowVideoPopup] = useState(false);
    const [showShareButtons, setShowShareButtons] = useState(false);

    const toggleShareButtons = () => {
        setShowShareButtons(!showShareButtons);
    };

    // Function to handle scroll to a specific block
    const scrollToBlock = (blockId) => {
        document.getElementById(blockId).scrollIntoView({ behavior: 'smooth' });
    };

    const location = useLocation();
    const navigate = useNavigate();
    const isActive = (path) => location.pathname === path;

    const handleSignIn = () => {
        // Assuming 'Auth' is the route for your Auth.js component
        navigate('/auth');
    };

    const handleSeeHowItWorks = (event) => {
        event.preventDefault(); // Prevent the default behavior of the anchor element
        setShowVideoPopup(true);
    };

    const closeVideoPopup = () => {
        setShowVideoPopup(false);
    };

    return (
        <div className='home'>
            {showVideoPopup && (
                <div className="video-popup">
                    <div className="video-popup-content">
                        <span className="close-popup" onClick={closeVideoPopup}>
                            &times;
                        </span>
                        <iframe
                            width="700px"
                            height="400px"
                            src="https://embed.app.guidde.com/playbooks/ftb6y5Hd98BHESB3WRXVUH"
                            title="How to use Swift Product and core functionalities"
                            frameborder="0"
                            referrerpolicy="unsafe-url"
                            allowfullscreen="true"
                            allow="clipboard-write"
                            sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-forms allow-same-origin allow-presentation"
                        ></iframe>
                    </div>
                </div>
            )}
            <div className='homeArea'>
                <header>
                    <div className="header-left">
                        <img src={Logo} className='LogoMainPage' alt='Swift Product, create your product 10x faster, with AI Asssistance for your documentation.' />
                        <img src={LogoM} className='MLogoMainPage' alt='Swift Product, create your product 10x faster, with AI Asssistance for your documentation.' />
                    </div>
                    <div className="header-center">
                        <a onClick={() => scrollToBlock('home')}>Home</a>
                        <a onClick={() => scrollToBlock('our-service')}>Our Service</a>
                        <a onClick={() => scrollToBlock('faq')}>Faq</a>
                    </div>
                    <div className="header-right">
                        <NavLink to='/auth' style={{ textDecoration: 'none' }}>
                            <div className='loginButton' onClick={handleSignIn}>
                                Sign In
                            </div>
                        </NavLink>
                    </div>
                </header>

                <div className="body">
                    <div className='share-buttons'>
                        <div className='share-button' onClick={toggleShareButtons}>
                            <IoMdShare />
                        </div>
                        {showShareButtons && (
                            <div className='social-share-buttons'>
                                <TwitterShareButton
                                    url='https://swiftproduct.io/'
                                    title='Check out Swift Product - Create your product 10x faster with AI assistance for documentation!'
                                >
                                    <TwitterIcon size={32} round />
                                </TwitterShareButton>
                                <FacebookShareButton
                                    url='https://swiftproduct.io/'
                                    quote='Check out Swift Product - Create your product 10x faster with AI assistance for documentation!'
                                >
                                    <FacebookIcon size={32} round />
                                </FacebookShareButton>
                                <LinkedinShareButton
                                    url='https://swiftproduct.io/'
                                    title='Check out Swift Product - Create your product 10x faster with AI assistance for documentation!'
                                >
                                    <LinkedinIcon size={32} round />
                                </LinkedinShareButton>
                            </div>
                        )}
                    </div>
                    <div id="home" className="block first-block">
                        <div className="text-section">
                            <h1>Make Your Product 10x faster</h1>
                            <h2>Our AI-Powered Swift Product</h2>
                            <h3>Generate full Documentations for your feature product or features</h3>
                            <div className='buttonsMain'>
                                <NavLink to='/auth' style={{ textDecoration: 'none', width: '30%', textAlign: 'center' }}>
                                    <div className='loginButton' onClick={handleSignIn}>
                                        Get started
                                    </div>
                                </NavLink>
                                <a href='' className='howItWork' onClick={handleSeeHowItWorks}>
                                    See how it works <IoIosPlayCircle style={{ fontSize: '24px' }} />
                                </a>

                            </div>
                        </div>
                        <div className="image-section">
                            {/* <img src={MainPageImage} alt='Make your product 10x faster' className='mainPictureStyle' /> */}
                            <Lottie
                                animationData={animationData}
                                loop={true}
                                autoplay={true}
                            />
                        </div>
                    </div>

                    <div id="our-service" className="block second-block">
                        <h2>Our Service</h2>
                        <div className='cardBoard'>
                            <div className='mainCard'>
                                <div>
                                    <img src={Design} className='imgInCard' alt='Image to Text AI' />
                                </div>
                                <div className='cardWithImage'>
                                    <h2>Design to Documentations</h2>
                                    <p>Unleash the power of AI in your design process with our 'Design to Documentation' service. Simply provide your design, mockup, or wireframe, and let our AI assistant handle the rest.</p>
                                </div>
                            </div>
                            <div className='mainCard'>
                                <div>
                                    <img src={Text} className='imgInCard' alt='Text to Text AI' />
                                </div>
                                <div className='cardWithImage'>
                                    <h2>Text to Documentations</h2>
                                    <p>Transform your ideas and hypotheses into well-structured, professional documentation with our 'Text to Documentation' service.</p>
                                </div>
                            </div>
                        </div>
                        <div className='cardBoard'>
                            <div className='mainCard'>
                                <div>
                                    <img src={Free} className='imgInCard' alt='Free SaaS Product AI' />
                                </div>
                                <div className='cardWithImage'>
                                    <h2>Free Version</h2>
                                    <p>Embrace the power of efficiency with our Free Version, designed to speed up your product development process by 10 times, absolutely free of charge.</p>
                                </div>
                            </div>
                            <div className='mainCard'>
                                <div>
                                    <img src={Rocket} className='imgInCard' alt='Fast growing startup, AI, product management' />
                                </div>
                                <div className='cardWithImage'>
                                    <h2>Fast Growing Product</h2>
                                    <p>Our product stands out as a fast-growing solution in the market, consistently integrating up-to-date and unique features.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="faq" className="block third-block">
                        <FAQMain />
                    </div>

                    <div id="contact-us" className="block fourth-block">
                        <div className='subscribeBlock'>
                            <div className='subscribeText'>
                                <h2>Subscribe to stay updated and follow the latest news</h2>
                            </div>
                            <div className='subscribeArea'>
                                <form action="https://api.web3forms.com/submit" method="POST">
                                    <input type="hidden" name="access_key" value="21dcc3aa-711a-4617-84ce-9413956e2eee" />
                                    <input placeholder='Write your email...' type="email" name="email" required />
                                    <div className="h-captcha" data-captcha="true"></div>
                                    <button type="submit" className='subscribeButton'>Subscribe</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <div className='footerArea'>

                    <div className='footerBlock'>
                        <h3>Links</h3>
                        <a href=''>Our Service</a>
                        <a href=''>FAQ</a>
                        <a href=''>Design to Doc</a>
                        <a href=''>Text to Doc</a>
                    </div>
                    <div className='footerBlock'>
                        <h3>Contact us</h3>
                        <a href='mailto:swiftproduct.ai@gmail.com'>swiftproduct.ai@gmail.com</a>
                    </div>
                    <div className='footerBlock'>
                        <h3>Social Network</h3>
                        <a href='https://twitter.com/SwiftProduct_io'>Twitter</a>
                        <a href=''>Instagram</a>
                        <a href=''>Linkedin</a>
                        <a href='https://facebook.com/'>Facebook</a>
                    </div>
                </div>

            </footer>
            <div className='divide'>

            </div>
            <div className='swiftProduct'>
                © 2023 Swift Product. All rights reserved. Unauthorized reproduction, copying, or distribution of any part of this product or its content is strictly prohibited.
            </div>
        </div>
    );
};

export default Home;
