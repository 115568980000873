import React, { useState, useEffect } from 'react';
import { IoIosArrowBack, IoIosArrowForward, IoIosImages, IoIosCreate, IoIosLaptop, IoIosFlash, IoMdSettings, IoIosMailUnread  } from 'react-icons/io'; // Import arrow icons
import './SideMenu.css';
import logoImg from './images/logo.svg';
import logoImgM from './images/logo-m.svg';
import { NavLink, useLocation } from 'react-router-dom';
import UpgradeBlock from './UpgradeBlock';

const SideMenu = () => {
    const [isExpanded, setIsExpanded] = useState(true); // State to manage menu expansion
    const location = useLocation();

    const isActive = (path) => location.pathname === path;

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 767) {
                setIsExpanded(false); // Collapse menu on mobile
            } else {
                setIsExpanded(true); // Expand menu on larger screens
            }
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Call handleResize initially in case the screen width is not in a mobile view
        handleResize();

        // Clean up
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleMenu = () => {
        setIsExpanded(!isExpanded); // Toggle the menu
    };

    return (
        <div className={`side-menu ${isExpanded ? 'expanded' : 'collapsed'}`}>
            <div className='logoArea'>
                {isExpanded && <img src={logoImg} className='logo' alt='Swift Product, create your product 10x faster, with AI Asssistance for your documentation.'/>}
                {!isExpanded && <img src={logoImgM} className='logoM' alt='Swift Product, create your product 10x faster, with AI Asssistance for your documentation.'/>}
            </div>
            <div className='menuItems'>
                <NavLink to='/design-to-doc' style={isActive('/design-to-doc') ? { backgroundColor: '#7B3AED', color: '#fff', borderRadius: '5px' } : {}}>
                    <div className='menuItemMain'>
                        <div className='menuItem'>
                            <IoIosImages style={isActive('/design-to-doc') ? { fontSize: '20px', backgroundColor: '#7B3AED', padding: '10px', borderRadius: '5px' } : {fontSize: '20px', padding: '10px', borderRadius: '5px'}} />
                            {isExpanded && 'Design to Doc'}
                        </div>
                        {isExpanded &&< IoIosArrowForward />}
                    </div>
                </NavLink>
                <NavLink to='/text-to-doc' style={isActive('/text-to-doc') ? { backgroundColor: '#7B3AED', color: '#fff', borderRadius: '5px' } : {}}>
                    <div className='menuItemMain'>
                        <div className='menuItem'>
                            <IoIosCreate style={isActive('/text-to-doc') ? { fontSize: '20px', backgroundColor: '#7B3AED', padding: '10px', borderRadius: '5px' } : {fontSize: '20px', padding: '10px', borderRadius: '5px'}} />
                            {isExpanded && 'Text to Doc'}
                        </div>
                        {isExpanded &&< IoIosArrowForward />}
                    </div>
                </NavLink>
                <NavLink to='/work-board' style={isActive('/work-board') ? { backgroundColor: '#7B3AED', color: '#fff', borderRadius: '5px' } : {}}>
                    <div className='menuItemMain'>
                        <div className='menuItem'>
                            <IoIosLaptop style={isActive('/work-board') ? { fontSize: '20px', backgroundColor: '#7B3AED', padding: '10px', borderRadius: '5px' } : {fontSize: '20px', padding: '10px', borderRadius: '5px'}} />
                            {isExpanded && 'Work board'}
                        </div>
                        {isExpanded &&< IoIosArrowForward />}
                    </div>
                </NavLink>
                <NavLink to='/pricing' style={isActive('/pricing') ? { backgroundColor: '#7B3AED', color: '#fff', borderRadius: '5px' } : {}}>
                    <div className='menuItemMain'>
                        <div className='menuItem'>
                            <IoIosFlash style={isActive('/pricing') ? { fontSize: '20px', backgroundColor: '#7B3AED', padding: '10px', borderRadius: '5px' } : {fontSize: '20px', padding: '10px', borderRadius: '5px'}} />
                            {isExpanded && 'Pricing Plans'}
                        </div>
                        {isExpanded &&< IoIosArrowForward />}
                    </div>
                </NavLink>
                <NavLink to='/contact' style={isActive('/contact') ? { backgroundColor: '#7B3AED', color: '#fff', borderRadius: '5px' } : {}}>
                    <div className='menuItemMain'>
                        <div className='menuItem'>
                            <IoIosMailUnread  style={isActive('/contact') ? { fontSize: '20px', backgroundColor: '#7B3AED', padding: '10px', borderRadius: '5px' } : {fontSize: '20px', padding: '10px', borderRadius: '5px'}} />
                            {isExpanded && 'Contact us'}
                        </div>
                        {isExpanded &&< IoIosArrowForward />}
                    </div>
                </NavLink>
            </div>
            {window.innerWidth > 767 && ( // Render toggle button conditionally
                <div className='expandArea'>
                    <div className='toggle-button' onClick={toggleMenu}>
                        {isExpanded ? <IoIosArrowBack /> : <IoIosArrowForward />}
                    </div>
                </div>
            )}
            <div className='planCard'>
            {isExpanded && <UpgradeBlock />}
            </div>
        </div>
    );
};

export default SideMenu;
