import './home.css'; // Assuming you have a CSS file for styling
import Lottie from 'lottie-react';
import animationData from './images/404notfound.json';
import React, { useEffect, useState } from 'react';
import './home.css'; // Assuming you have a CSS file for styling
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Logo from './images/logo.svg'
import LogoM from './images/logo-m.svg'
import { IoIosArrowBack} from 'react-icons/io'; // Import arrow icons


window.ml = function () { };

const NotFound = () => {
    const [showVideoPopup, setShowVideoPopup] = useState(false);
    // Function to handle scroll to a specific block
    const scrollToBlock = (blockId) => {
        document.getElementById(blockId).scrollIntoView({ behavior: 'smooth' });
    };

    const location = useLocation();
    const navigate = useNavigate();
    const isActive = (path) => location.pathname === path;

    const handleSignIn = () => {
        // Assuming 'Auth' is the route for your Auth.js component
        navigate('/auth');
    };

    const handleSeeHowItWorks = (event) => {
        event.preventDefault(); // Prevent the default behavior of the anchor element
        setShowVideoPopup(true);
    };

    const closeVideoPopup = () => {
        setShowVideoPopup(false);
    };
    return (
        <div className='NotFoundPage'>
            <header>
                    <div className="header-left">
                        <img src={Logo} className='LogoMainPage' alt='Swift Product, create your product 10x faster, with AI Asssistance for your documentation.' />
                        <img src={LogoM} className='MLogoMainPage' alt='Swift Product, create your product 10x faster, with AI Asssistance for your documentation.' />
                    </div>
                    <div className="header-center">
                        
                    </div>
                    <div className="header-right">
                        <NavLink to='/' style={{ textDecoration: 'none' }}>
                            <div className='loginButton'>
                                <IoIosArrowBack /> Back
                            </div>
                        </NavLink>
                    </div>
                </header>
            <div className='notFoundArea'>
                <div className='label404'>
                    404 error
                </div>
                <h1>Oops... Page is not found!</h1>
                <p>Sorry, the page you are looking for doesn't exist or has been moved.</p>
                <Lottie
                    animationData={animationData}
                    loop={true}
                    autoplay={true}
                />
            </div>
        </div>
    );
};

export default NotFound;
