import React, { useState, useEffect, useRef } from 'react';
import { db, auth } from './firebase';
import { encodeImage, fetchUserData, generateTextWithGPT35, parseUserStories, parseAcceptanceCriteria, parseDetailedDescription } from './utils';
import Collapsible from './Collapsible';
import { useUserData } from './useUserData'; // Adjust the path if necessary
import axios from 'axios';
import { updateDoc, doc, collection, addDoc } from 'firebase/firestore';
import FAQ from './Faq';
import generateImage from './images/generate.png';
import YourPreviousDocsComponent from './YourPreviousDocsComponent';
import RocketLoad from './images/rocketload.png';
import Lottie from 'lottie-react';
import rocketAnimation from './images/RocketAnim1.json';
import fetchAndStoreOrders from './fetchOrders'; // Adjust the path as necessary
import { checkStatusExpiryDate, updateGenerateAmount, updateGenerateRefreshDate } from './firebase'; // Ensure this is the correct path

const YourComponent = () => {
  const [apiResponse, setApiResponse] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageBase64, setImageBase64] = useState(null);
  const [generatedText, setGeneratedText] = useState('');
  const [acceptanceCriteria, setAcceptanceCriteria] = useState(false);
  const [detailedDescription, setDetailedDescription] = useState(false);
  const [acceptanceCriteriaText, setAcceptanceCriteriaText] = useState('');
  const [detailedDescriptionText, setDetailedDescriptionText] = useState('');
  const [showUserStories, setShowUserStories] = useState(false);
  const [showAcceptanceCriteria, setShowAcceptanceCriteria] = useState(false);
  const [showDetailedDescription, setShowDetailedDescription] = useState(false);
  const [userStories, setUserStories] = useState(false);
  const [generateAmount, setGenerateAmount] = useState(0); // New state variable for generateAmount
  const [userId, setUserId] = useState(null);
  const userData = useUserData();
  const [selectedImages, setSelectedImages] = useState([null]);
  const [imagesBase64, setImagesBase64] = useState([null]);
  const [aboutProductText, setAboutProductText] = useState('');
  const [currentView, setCurrentView] = useState('generate');
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);


  const showGenerateView = () => setCurrentView('generate');
  const showPreviousDocsView = () => setCurrentView('previousDocs');

  const isActive = (viewName) => currentView === viewName;

  useEffect(() => {
    // Set up the interval for both functions
    const intervalId = setInterval(() => {
      fetchAndStoreOrders();
    }, 60000); // 180000 ms = 3 minutes
    

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // Set up the interval for both functions
    const intervalId = setInterval(() => {
      checkStatusExpiryDate();
      updateGenerateAmount();
      updateGenerateRefreshDate();
    }, 180000); // 180000 ms = 3 minutes
    

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUserId(user.uid);
        fetchUserData(user.uid, db, setGenerateAmount); // Make sure this function is defined correctly
      }
    });
    return () => unsubscribe(); // Clean up the subscription
  }, []);

  const handleAddImage = () => {
    if (selectedImages.length < 3) {
      setSelectedImages([...selectedImages, null]);
      setImagesBase64([...imagesBase64, null]);
    } else {
      alert("Maximum of 3 images can be uploaded.");
    }
  };

  const countSelectedOptions = () => {
    let count = 0;
    if (userStories) count++;
    if (acceptanceCriteria) count++;
    if (detailedDescription) count++;
    return count;
  };

  const api_key = 'sk-proj-pfmh7ZUq9lqj2nCsvtrKT3BlbkFJeoMLbke33p5lOIY0h4VQ'; // Replace with your OpenAI API key
  const fileInputRef = useRef([]);

  const handleImageChange = async (event, index) => {
    const file = event.target.files[0];
    if (!file) return;

    const updatedSelectedImages = [...selectedImages];
    updatedSelectedImages[index] = file;
    setSelectedImages(updatedSelectedImages);

    const imageBase64 = await encodeImage(file);
    if (!imageBase64) return;

    const updatedImagesBase64 = [...imagesBase64];
    updatedImagesBase64[index] = imageBase64;
    setImagesBase64(updatedImagesBase64);
  };

  const decreaseGenerateAmountInFirestore = async (userId, newAmount) => {
    const userDocRef = doc(db, 'Users', userId);
    try {
      await updateDoc(userDocRef, {
        generateAmount: newAmount
      });
    } catch (error) {
      console.error('Error updating generateAmount in Firestore:', error);
    }
  };

  const storeResponseInHistory = async (userId, responses) => {
    const historyRef = collection(db, 'History', userId, 'responses');

    // Create a new date object
    const now = new Date();
    // Format the date and time
    const dateTimeFormatted = `${now.getDate().toString().padStart(2, '0')}/${(now.getMonth() + 1).toString().padStart(2, '0')}/${now.getFullYear()} ${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}`;

    try {
      await addDoc(historyRef, {
        ...responses,
        dateTime: dateTimeFormatted, // Add the formatted date and time to the responses object
        type: "Image-to-Doc" // Add the type field
      });
    } catch (error) {
      console.error('Error storing response in history:', error);
    }
  };

  const handleImageSubmit = async () => {
    if (imagesBase64.length === 0 || imagesBase64.some(image => !image) || generateAmount <= 0) return;

    setApiResponse(null); // Reset the previous responses

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${api_key}`,
    };

    setIsLoading(true);
    setLoadingProgress(0);

    let progressIncrement;
    const selectedOptionsCount = countSelectedOptions();
    switch (selectedOptionsCount) {
      case 1:
        progressIncrement = 5;
        break;
      case 2:
        progressIncrement = 4;
        break;
      case 3:
        progressIncrement = 2;
        break;
      default:
        progressIncrement = 5; // Default value if no options are selected
    }

    let progressInterval = setInterval(() => {
      setLoadingProgress(oldProgress => {
        if (oldProgress < 100) {
          return Math.min(oldProgress + progressIncrement, 100); // Ensure it does not exceed 100
        } else {
          clearInterval(progressInterval); // Clear interval when progress is 100%
          return 100;
        }
      });
    }, 1000); // Adjust time interval as needed

    const payload = {
      model: 'gpt-4-vision-preview',
      messages: [
        {
          role: 'user',
          content: [
            { type: 'text', text: 'What’s in these images?' },
            ...imagesBase64.map(imageBase64 => ({
              type: 'image_url',
              image_url: {
                url: `data:image/jpeg;base64,${imageBase64}`,
              },
            })),
          ],
        },
      ],
      max_tokens: 1500,
    };

    try {
      // Perform the default request and get the image description
      const defaultResponse = await axios.post('https://api.openai.com/v1/chat/completions', payload, { headers });
      console.log("Response:", defaultResponse.data);

      const imageDescription = defaultResponse.data.choices[0]?.message?.content;
      setApiResponse(defaultResponse.data);

      // User Stories Request
      let userStoriesResponse, acceptanceCriteriaResponse, detailedDescriptionResponse;

      if (userStories) {
        try {
          const response = await generateTextWithGPT35(`Provide me User Stories about this app - ${aboutProductText} with this description: ${imageDescription}`, api_key, setGeneratedText);
          userStoriesResponse = response?.data?.choices[0]?.message?.content;
          console.log("User Stories Response: ", userStoriesResponse); // Debugging
        } catch (error) {
          console.error('Error in User Stories Request:', error);
        }
      }

      // Acceptance Criteria Request
      if (acceptanceCriteria) {
        try {
          const response = await generateTextWithGPT35(`Please provide me Acceptance Criteria about this app - ${aboutProductText} with this description: ${imageDescription}`, api_key, setAcceptanceCriteriaText);
          acceptanceCriteriaResponse = response?.data?.choices[0]?.message?.content;
          console.log("Acceptance Criteria Response: ", acceptanceCriteriaResponse); // Debugging
        } catch (error) {
          console.error('Error in Acceptance Criteria Request:', error);
        }
      }

      // Detailed Description Request
      if (detailedDescription) {
        try {
          const response = await generateTextWithGPT35(`Please provide me Detailed Product Description about this app - ${aboutProductText} with this description: ${imageDescription}`, api_key, setDetailedDescriptionText);
          detailedDescriptionResponse = response?.data?.choices[0]?.message?.content;
          console.log("Detailed Description Response: ", detailedDescriptionResponse); // Debugging
        } catch (error) {
          console.error('Error in Detailed Description Request:', error);
        }
      }

      const responses = {
        firstResponse: userStoriesResponse || 'No response',
        secondResponse: acceptanceCriteriaResponse || 'No response',
        thirdResponse: detailedDescriptionResponse || 'No response',
      };

      await storeResponseInHistory(userId, responses);

      // Decrease generateAmount by 1 after all requests complete
      const newAmount = generateAmount - 1;
      setGenerateAmount(newAmount);

      // Update the generateAmount in Firestore
      await decreaseGenerateAmountInFirestore(userId, newAmount);

    } catch (error) {
      console.error('Error fetching data:', error);
    }

    clearInterval(progressInterval); // Make sure to clear this interval when the responses are received
    setIsLoading(false);

  };

  const LoadingBar = ({ progress, rocketImage }) => {
    return (
      <div style={{ width: '100%', backgroundColor: '#ddd', borderRadius: '10px' }}>
        <div style={{
          width: `${progress}%`,
          height: '20px',
          backgroundColor: '#7B3AED',
          position: 'relative', // Allows absolute positioning of the rocket within this div
          borderRadius: '10px'
        }}>
          <img
            src={rocketImage}
            style={{
              position: 'absolute',
              right: 0,
              transform: `translateX(${100 - progress}%)`,
              height: '40px', // Adjust as necessary to fit the progress bar
              top: '-10px'
            }}
            alt="Rocket Load, for AI Assistance product, create your product documentation 10x faster!"
          />
        </div>
      </div>
    );
  };



  // Example usage (replace 'userId' with the actual user ID)
  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (user) {
        fetchUserData(userId, db, setGenerateAmount);
      }
    });
  }, []);

  useEffect(() => {
    if (apiResponse) {
      const imageDescription = apiResponse.choices[0]?.message?.content;
      if (imageDescription) {
        generateTextWithGPT35(`${imageDescription}`);
      }
    }
  }, [apiResponse]);

  // Call this function when the Save button is clicked
  const handleContentChange = (newContent, type) => {
    if (type === 'userStories') {
      setGeneratedText(newContent);
    } else if (type === 'acceptanceCriteria') {
      setAcceptanceCriteriaText(newContent);
    } else if (type === 'detailedDescription') {
      setDetailedDescriptionText(newContent);
    }
  };

  const handleRemoveImage = (index) => {
    const updatedSelectedImages = [...selectedImages];
    const updatedImagesBase64 = [...imagesBase64];

    updatedSelectedImages.splice(index, 1);
    updatedImagesBase64.splice(index, 1);

    setSelectedImages(updatedSelectedImages);
    setImagesBase64(updatedImagesBase64);
  };


  const handleButtonClick = (index) => {
    fileInputRef.current[index].click();
  };


  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      // You can show some notification to the user that text has been copied
      console.log('Text copied to clipboard');
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  };


  return (
    <div className='imageToDocArea'>
      <h1>Design to Document</h1>
      <div className='imageLeftRightArea'>
        <div className='leftImageDoc'>
          <h3 className='titleFieldsFirst'>Images</h3>
          <p className='descFields'>Maximum number of images: 3. Supported formats: JPEG, PNG.</p>
          {selectedImages.map((image, index) => (
            <div className="upload-area" key={index}>
              {image ? (
                <div className="image-preview">
                  <img className="imagePreview" src={URL.createObjectURL(image)} alt={`Selected ${index}`} />
                </div>
              ) : (
                <>
                  <button className="upload-btn" onClick={() => handleButtonClick(index)}>Upload</button>
                  <input type="file" accept="image/*" onChange={(e) => handleImageChange(e, index)} ref={el => fileInputRef.current[index] = el} style={{ display: 'none' }} />
                </>
              )}
            </div>
          ))}
          <div className="image-control-buttons">
            {selectedImages.length < 3 && (
              <button onClick={handleAddImage} className='addImageButton'>Add Image</button>
            )}
            {selectedImages.length > 1 && (
              <button onClick={() => handleRemoveImage(selectedImages.length - 1)} className='addImageButton'>Remove Image</button>
            )}
          </div>
          <h3 className='titleFields'>About product: <span className='optionalLabel'>(optional)</span></h3>
          <p className='descFields'>Enter product description, instructions, or requirements.</p>
          <textarea
            placeholder="About Product"
            value={aboutProductText}
            onChange={(e) => setAboutProductText(e.target.value)}
            className="about-product-textarea"
          />
          <div>
            <div>
              <h3 className='titleFields'>Options</h3>
              <p className='descFields'>Each option will be included in the document.</p>
              <div className='optionsArea'>
                <button
                  className={`toggleButton ${userStories ? 'active' : 'inactive'}`}
                  onClick={() => setUserStories(!userStories)}
                >
                  {userStories ? '' : ''} User Stories
                </button>
                <button
                  className={`toggleButton ${acceptanceCriteria ? 'active' : 'inactive'}`}
                  onClick={() => setAcceptanceCriteria(!acceptanceCriteria)}
                >
                  {acceptanceCriteria ? '' : ''} Acceptance Criteria
                </button>
                <button
                  className={`toggleButton ${detailedDescription ? 'active' : 'inactive'}`}
                  onClick={() => setDetailedDescription(!detailedDescription)}
                >
                  {detailedDescription ? '' : ''} Detailed Description
                </button>
              </div>
            </div>
          </div>
          <div className='submitArea'>
            <button
              onClick={handleImageSubmit}
              className='submitButton'
              disabled={!userStories && !acceptanceCriteria && !detailedDescription}
            >
              Generate
            </button>
            <p>You have {generateAmount} Credit to generate</p>
          </div>
        </div>
        <div className='rightImageDoc'>
          <div>
            <div className='switchWorkSpace'>
              <div
                className="switchButton"
                onClick={showGenerateView}
                style={isActive('generate') ? { backgroundColor: '#7B3AED', color: '#fff', borderRadius: '5px', padding: '10px 20px' } : {}}
              >
                Generate
              </div>
              <div
                className="switchButton"
                onClick={showPreviousDocsView}
                style={isActive('previousDocs') ? { backgroundColor: '#7B3AED', color: '#fff', borderRadius: '5px', padding: '10px 20px' } : {}}
              >
                Previous Docs
              </div>
            </div>
            {isLoading ? (
              <div className="spinner-container">
                <Lottie
                  animationData={rocketAnimation}
                  loop={true}
                  autoplay={true}
                  style={{ width: '300px' }}
                />
              </div>
            ) : (
              <>
            {currentView === 'generate' && (
              <div>
                {generatedText || acceptanceCriteriaText || detailedDescriptionText ? (
                  <>
                    {generatedText && (
                      <Collapsible
                        title="User Stories:"
                        content={parseUserStories(generatedText)}
                        onContentChange={(newContent) => handleContentChange(newContent, 'userStories')}
                        onCopy={() => copyToClipboard(generatedText)}
                      />
                    )}
                    {acceptanceCriteriaText && (
                      <Collapsible
                        title="Acceptance Criteria:"
                        content={parseAcceptanceCriteria(acceptanceCriteriaText)}
                        onContentChange={(newContent) => handleContentChange(newContent, 'acceptanceCriteriaText')}
                        onCopy={() => copyToClipboard(acceptanceCriteriaText)}
                      />
                    )}
                    {detailedDescriptionText && (
                      <Collapsible
                        title="Detailed Description:"
                        content={parseDetailedDescription(detailedDescriptionText)}
                        onContentChange={(newContent) => handleContentChange(newContent, 'detailedDescriptionText')}
                        onCopy={() => copyToClipboard(detailedDescriptionText)}
                      />
                    )}
                  </>
                ) : (
                  <div className="empty-message">
                    <img src={generateImage} alt='Here is empty document, but you can create with our AI Assistance, which help you create Product Documentation'/>
                    <div>You have not recently generated any docs</div>
                    <div>Use left nav to generate new request</div>
                  </div>
                )}
              </div>
            )}
            {currentView === 'previousDocs' && (
              <YourPreviousDocsComponent />
            )}
            </>
            )}
          </div>
          <div>
            <FAQ />
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourComponent;
