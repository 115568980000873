import axios from 'axios';
import { db } from './firebase';
import { collection, query, where, getDocs, updateDoc, addDoc, doc } from 'firebase/firestore';

const fetchAndStoreOrders = async () => {
  try {
    const config = {
      method: 'get',
      url: 'https://api.lemonsqueezy.com/v1/orders',
      headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5NGQ1OWNlZi1kYmI4LTRlYTUtYjE3OC1kMjU0MGZjZDY5MTkiLCJqdGkiOiIwNzg3YWM0MDIxNTNlMzMwNzEyMmVlMmQxMzI2MzU2MzZiNGI0MWE1YWZmZTU1YmUwYmFjYjM1ZDc3ZWE2MTQxYmJkMzM1ZGMxMTU0M2Y2MiIsImlhdCI6MTcwNjEyMTMxOS41MjA3OTUsIm5iZiI6MTcwNjEyMTMxOS41MjA3OTgsImV4cCI6MjAyMTc0MDUxOS40OTcwOTgsInN1YiI6IjE0MDY0NTIiLCJzY29wZXMiOltdfQ.y7FD9MdRLc1PbWL1T-4VILq4e5SbANT1-5A1Kej-3lVllOosOHmhBmJzNFrsASI8f9ZgqaXEONxdWvPzXTxrNIbPp_Rm8TgWfTtULC0UFRoKcKXSNecn6iqHIVJCX6SMP6JuF3zTLLetFmsh4g1L_L8THu_peitg_0oC8JDoNJlw-645dDQ1cK3J6n5M_jWr8TmVEhjSq197WuP1TUYotFSmUspWxqx5k55U4Xk837EyOZo3zON-RPFSFhuyJ2wzdFEmTp4KoA3gEhN9UnfoYw0JIEYpDrcgHZWbeZ2jTcar6wZyoqo8xgLi-5USpq6R84QIfUs6RhDWNwhVQZk7k033fAEDYgIfyLjZdoW4OeASo4TLdHfQwRZYMv21ld0xPfFG_eidU1PRDI3JX2giCQANryGHgVzGxkWb0UKwhA3_NEttqbsWxFNHod67-cRabdVMZyDzG4MvRJsJrZKnrqmoJenAibmRl9vZkP9Qdayu57nzfdCX4YnqF84r0GZM',
      },
    };

    const response = await axios.request(config);
    const orders = response.data.data; // Adjust based on actual response structure
    const paymentsCol = collection(db, 'payments');
    const usersCol = collection(db, 'Users');

    for (const order of orders) {
      const orderQuery = query(paymentsCol, where('id', '==', order.id));
      const querySnapshot = await getDocs(orderQuery);

      // Proceed only if no existing document with the same order ID is found
      if (querySnapshot.empty) {
        // No existing document, so add a new one
        await addDoc(paymentsCol, order);
      } else {
        // Existing document found, you might want to update it
        const paymentDoc = querySnapshot.docs[0];
        const userEmail = paymentDoc.data().attributes.user_email;
        console.log('User Email:', userEmail); // Log user email

        const userQuery = query(usersCol, where('email', '==', userEmail));
        const userQuerySnapshot = await getDocs(userQuery);

        if (!userQuerySnapshot.empty) {
          const userDoc = userQuerySnapshot.docs[0];
          const paymentStatus = paymentDoc.data().attributes.status;
          console.log('Payment Status:', paymentStatus); // Log payment status
          const productId = paymentDoc.data().attributes.first_order_item.product_id;
          console.log('Product ID:', productId); // Log product ID

          if (paymentStatus === 'paid') {
            let newStatus;

            if (productId === 165512) {
              newStatus = 'basic';
            } else if (productId === 165588) {
              newStatus = 'premium';
            } else if (productId === 165589) {
              newStatus = 'enterprise';
            }

            if (newStatus) {
              console.log('Updating user status to:', newStatus);
              await updateDoc(doc(usersCol, userDoc.id), { status: newStatus });

              // Parse the created_at date from the order
              const orderCreatedAt = new Date(order.attributes.created_at);

              // Add 30 days to the parsed date
              const expiryDate = new Date(orderCreatedAt);
              expiryDate.setDate(expiryDate.getDate() + 30);

              // Update the statusExpiryDate in the Users collection
              await updateDoc(doc(usersCol, userDoc.id), { statusExpiryDate: expiryDate });

              console.log('Updated statusExpiryDate:', expiryDate); // Log updated statusExpiryDate
            }
          }
        }
      }
    }
  } catch (error) {
    console.error('Error fetching and storing orders:', error.response ? error.response.data : error);
  }
};

export default fetchAndStoreOrders;