import React, { useState } from 'react';
import { IoIosAddCircleOutline, IoIosRemoveCircleOutline } from "react-icons/io";

const FAQMain = () => {
    const faqs = [
        { title: "Is Swift Product free?", content: "Swift Product a freemium monetization model. Users can access it for free, but with certain limitations. To unlock additional features or remove limits, you can choose from one of our available plans." },
        { title: "Which plans do you have?", content: "We offer four plans: Free, Basic, Premium, and Enterprise. Each plan is designed to be useful for everyone, and we provide competitive prices to cater to all users." },
        { title: "How long I can storage my documentations?", content: "There is no limit to storage duration for your Swift Product documentations. You can save all your creations indefinitely, edit them, and make copies without any limits." },
        { title: "Do you provide the support?", content: "Yes, we provide support. Feel free to contact us at swiftproduct.io@gmail.com. We offer different types of support for each plan, ensuring assistance for everyone." },
        { title: "How do you handle companies' private data?", content: "We take company privacy and security very seriously. We do not share your data with our users or any companies. We store your data on our cloud provider's premises in a secure fashion (a standard practice among vendors for any cloud-based service) and all our AI service providers are prohibited from using the data for anything other than misuse monitoring. Additionally, you have the option to delete and wipe out your historical data at any time." },
    ];

    return (
        <div className="faq-container">
            <h1 className='faq-main-title'>Frequently Asked Questions</h1>
            {faqs.map((faq, index) => (
                <FAQItem key={index} title={faq.title} content={faq.content} />
            ))}
        </div>
    );
};

const FAQItem = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="faq-item">
            <div className="faq-title" onClick={() => setIsOpen(!isOpen)}>
                <div><span>{title}</span></div>
                <div>{isOpen ? <IoIosRemoveCircleOutline style={{ fontSize: '20px' }} /> : <IoIosAddCircleOutline style={{ fontSize: '20px' }} />}</div>
            </div>
            <div className="faq-content" style={{ maxHeight: isOpen ? '500px' : '0px', overflow: 'hidden' }}>
                <div className='faq-content-style'>{content}</div>
            </div>
        </div>
    );
};


export default FAQMain;
