import React, { useState, useEffect } from 'react';
import { db, auth } from './firebase';
import { collection, getDocs, query, doc, updateDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import './WorkBoard.css';
import { Editor, EditorState, convertToRaw, ContentState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { RichUtils } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import { IoMdCreate } from "react-icons/io";

const WorkBoard = () => {
    const [userHistory, setUserHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    // Additional state for editing content
    const [editableContent, setEditableContent] = useState('');
    const [currentHistoryId, setCurrentHistoryId] = useState('');
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [isEditMode, setIsEditMode] = useState(false);
    const [editingTitleId, setEditingTitleId] = useState(null);  // to keep track of which item is being edited
    const [titleInput, setTitleInput] = useState('');  // current input for the editable title

    const handleTitleChange = (e) => {
        setTitleInput(e.target.value);
    };

    const toggleInlineStyle = (inlineStyle) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    };
    
    const toggleBlockType = (blockType) => {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    };
    

    const saveTitleToFirestore = async (historyItemId, index) => {
        if (!historyItemId) return;

        try {
            // Define the document reference before using it
            const historyDocRef = doc(db, 'History', auth.currentUser.uid, 'responses', historyItemId);
            await updateDoc(historyDocRef, {
                title: titleInput || `Product Card #${index + 1}`, // Use the input title or default title
            });

            setEditingTitleId(null);  // Exit editing mode
            // Fetch history again to reflect changes or update local state
            fetchHistory(auth.currentUser.uid);
        } catch (error) {
            console.error('Error updating title in Firestore:', error);
        }
    };

    const formatResponse = (content, type) => {
        console.log("Formatting response of type:", type); // Debugging statement

        if (typeof content !== 'string') return 'No content available';

        switch (type) {
            case 'userStories':
                const regex = /\n(?=\d+\.\s)/;

                // Split the text using the regex and map each story
                return content.split(regex).map(story => `<p>${story.trim()}</p>`).join('');
            case 'acceptanceCriteria':
                return content.split(/\d+\./).slice(1).map(criteria => `<p>${criteria.trim()}</p>`).join('');
            case 'productDescription':
                return `<p>${content.replace(/(\.)/g, '.$1<br><br>')}</p>`;
            default:
                return `<p>${content}</p>`;
        }
    };

    const openModal = (firstResponse, secondResponse, thirdResponse, historyItem) => {
        let combinedContent = '';

        if (firstResponse && firstResponse.trim() && firstResponse !== 'No response') {
            combinedContent += `<h2>User Stories</h2>${formatResponse(firstResponse, 'userStories')}`;
        }
        if (secondResponse && secondResponse.trim() && secondResponse !== 'No response') {
            if (combinedContent) combinedContent += '<br>'; // Add a break if there's already content
            combinedContent += `<h2>Acceptance Criteria</h2>${formatResponse(secondResponse, 'acceptanceCriteria')}`;
        }
        if (thirdResponse && thirdResponse.trim() && thirdResponse !== 'No response') {
            if (combinedContent) combinedContent += '<br>'; // Add a break if there's already content
            combinedContent += `<h2>Product Description</h2>${formatResponse(thirdResponse, 'productDescription')}`;
        }

        setModalContent(combinedContent); // Keep the HTML format for display

        // Initialize editorState with modal content
        if (!isModalOpen) {
            const contentState = stateFromHTML(combinedContent);
            setEditorState(EditorState.createWithContent(contentState));
        }

        const plainTextContent = htmlToPlainText(combinedContent);
        setEditableContent(plainTextContent); // Set converted plain text for editing
        setCurrentHistoryId(historyItem.id);

        setIsModalOpen(true);

    };

    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    };
    

    const handleContentChange = (e) => {
        setEditableContent(e.target.value);
    };

    const saveContentToFirestore = async () => {
        if (!currentHistoryId) {
            console.error('No history item selected for saving');
            return;
        }

        try {
            // Get the current content from the Editor
            const contentState = editorState.getCurrentContent();
            // Convert the content to HTML format
            const htmlContent = stateToHTML(contentState);

            // Split the HTML content back into the three categories
            // Here you might need a more robust method depending on how you format and combine the content
            const splitContent = htmlContent.split('<h2>'); // Split by the header tags you added
            let firstResponseContent = '';
            let secondResponseContent = '';
            let thirdResponseContent = '';

            // Assign the split contents to respective variables
            splitContent.forEach((content) => {
                if (content.includes('User Stories')) {
                    firstResponseContent = content.replace('User Stories</h2>', '').trim(); // removing the header tag
                } else if (content.includes('Acceptance Criteria')) {
                    secondResponseContent = content.replace('Acceptance Criteria</h2>', '').trim();
                } else if (content.includes('Product Description')) {
                    thirdResponseContent = content.replace('Product Description</h2>', '').trim();
                }
            });

            // Reference to the specific Firestore document
            const historyDocRef = doc(db, 'History', auth.currentUser.uid, 'responses', currentHistoryId);
            // Update the specific fields in Firestore
            await updateDoc(historyDocRef, {
                firstResponse: firstResponseContent, // Update the actual fields in Firestore
                secondResponse: secondResponseContent,
                thirdResponse: thirdResponseContent,
            });

            console.log('Content updated successfully in Firestore');

            // After updating Firestore, update local state to reflect changes
            const updatedUserHistory = userHistory.map(item => {
                if (item.id === currentHistoryId) {
                    return {
                        ...item,
                        firstResponse: firstResponseContent,
                        secondResponse: secondResponseContent,
                        thirdResponse: thirdResponseContent,
                    };
                }
                return item;
            });
            setUserHistory(updatedUserHistory);

            // Close the editor mode and reset the modal content
            setIsEditMode(false);
            setModalContent(htmlContent);
        } catch (error) {
            console.error('Error updating content in Firestore:', error);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setIsEditMode(false); // Reset edit mode when closing the modal
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                fetchHistory(user.uid);
            } else {
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, [auth]); // Only re-run if auth changes

    const fetchHistory = async (userId) => {
        try {
            const historyQuery = query(collection(db, 'History', userId, 'responses'));
            const querySnapshot = await getDocs(historyQuery);
            const historyData = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                if (!data.dateTime) {
                    console.error('Missing dateTime field', doc.id);
                    return;
                }
                const dateTimeParts = data.dateTime.split(/[/ :]/);
                if (dateTimeParts.length !== 5) {
                    console.error('Invalid dateTime format', data.dateTime);
                    return;
                }
                const dateObject = new Date(dateTimeParts[2], dateTimeParts[1] - 1, dateTimeParts[0], dateTimeParts[3], dateTimeParts[4]);
                console.log('Converted Date:', dateObject);
                historyData.push({ id: doc.id, ...data, dateObject });
            });
            historyData.sort((a, b) => b.dateObject - a.dateObject);
            setUserHistory(historyData);
        } catch (error) {
            console.error('Error fetching history:', error);
        } finally {
            setLoading(false);
        }
    };

    const copyToClipboard = async () => {
        try {
            const plainTextContent = htmlToPlainText(editableContent);
            await navigator.clipboard.writeText(plainTextContent);
            alert('Content copied to clipboard!'); // Or use a more sophisticated notification
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    const htmlToPlainText = (htmlString) => {
        let text = htmlString.replace(/<br\s*\/?>/gi, '\n'); // Replaces <br> with newlines
        text = text.replace(/<\/p>/gi, '\n'); // Converts end of paragraphs to newlines
        text = text.replace(/<[^>]+>/g, ''); // Removes any remaining HTML tags
        return text;
    };


    const renderResponseContent = (response) => {
        if (!response) {
            console.log('Response is undefined or null');
            return 'No content available';
        }

        // Check if the response is a string (directly the content)
        if (typeof response === 'string') {
            // Format the response if it's a list
            return formatResponse(response);
        }

        // Check if the response has 'choices'
        if (response.choices && response.choices.length > 0 && response.choices[0].message) {
            return formatResponse(response.choices[0].message.content);
        } else {
            console.log('Unexpected response structure:', response);
            return 'No content available';
        }
    };
    const enableEditMode = () => {
        setIsEditMode(true);
    };

    const Modal = ({ isOpen, onClose }) => {
        if (!isOpen) return null;

        return (
            <div className="modal-overlay" onClick={onClose}>
                <div className="modal-content" onClick={e => e.stopPropagation()}>
                    <div className='buttonHistoryArea'>
                        {isEditMode ? (
                            <>
                            <button onMouseDown={(e) => { e.preventDefault(); toggleInlineStyle('BOLD'); }}>Bold</button>
                            <button onMouseDown={(e) => { e.preventDefault(); toggleInlineStyle('ITALIC'); }}>Italic</button>
                            <button onMouseDown={(e) => { e.preventDefault(); toggleInlineStyle('UNDERLINE'); }}>Underline</button>
                            <button onMouseDown={(e) => { e.preventDefault(); toggleBlockType('header-one'); }}>H1</button>
                            <button onMouseDown={(e) => { e.preventDefault(); toggleBlockType('header-two'); }}>H2</button>
                            <button onClick={saveContentToFirestore} className='buttonHistoryModal'>Save</button>
                            </>
                        ) : (
                            <button onClick={() => setIsEditMode(true)} className='buttonHistoryModal'>Edit</button>
                        )}
                        <button onClick={copyToClipboard} className='buttonHistoryModal'>Copy</button>
                        <button onClick={closeModal} className='buttonHistoryModal'>Close</button>
                    </div>
                    {isEditMode ? (
                        <Editor editorState={editorState} onChange={setEditorState} handleKeyCommand={handleKeyCommand} />
                    ) : (
                        <div dangerouslySetInnerHTML={{ __html: modalContent }} />
                    )}
                </div>
            </div>
        );
    };


    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className='workBoardArea'>
            <h1 className='historyTitle'>Work Board</h1>
            {userHistory.length > 0 ? (
                <div className='HistoryContent'>
                    {userHistory.map((historyItem, index) => (
                        <div key={historyItem.id} className='historyItem'>
                            <div className='productCard'>
                                <p>{historyItem.dateTime}</p>
                                <p>{historyItem.type}</p>
                                {editingTitleId === historyItem.id ? (
                                    <>
                                        <input
                                            value={titleInput}
                                            onChange={handleTitleChange}
                                            autoFocus
                                        />
                                        <button className='saveButtonTitle' onClick={() => saveTitleToFirestore(historyItem.id, index)}>Save</button>
                                    </>
                                ) : (
                                    <>
                                        <div className='cardControl'>
                                            <h2>{historyItem.title || `Product Card #${index + 1}`}</h2>
                                            <IoMdCreate className='editIco' onClick={() => {
                                                setEditingTitleId(historyItem.id);
                                                setTitleInput(historyItem.title || `Product Card #${index + 1}`);
                                            }} />
                                        </div>
                                        <button className='saveButtonTitle' onClick={() => openModal(historyItem.firstResponse, historyItem.secondResponse, historyItem.thirdResponse, historyItem)}
                                        >Open</button>
                                    </>
                                )}
                            </div>
                            {/* Optionally show a preview or other details here */}
                        </div>
                    ))}
                </div>
            ) : (
                <div>No history found.</div>
            )}

            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} content={modalContent} />
        </div>
    );
};

export default WorkBoard;
