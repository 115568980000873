import React from 'react';
import { useLocation } from 'react-router-dom';

const SearchResultsPage = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get('query');

    // Logic to fetch and display search results based on `query`

    return (
        <div>
            <h1>Search Results for "{query}"</h1>
            {/* Display search results here */}
        </div>
    );
};

export default SearchResultsPage;
