import React from 'react';
import './PricingPlans.css';
import { IoIosCheckmarkCircle } from "react-icons/io";

const plans = [
    {
        name: 'Free',
        price: '0.00',
        buttonLabel: 'Current Plan',
        description: 'Best for one person, startups with few features.',
        optionOne: '5 generates per month',
        optionTwo: 'Save Documents',
        optionThree: 'Data privacy',
        optionFour: 'Free Support',
        url: ''
    },
    {
        name: 'Basic',
        price: '11.90',
        planLabel: 'Most Popular',
        buttonLabel: 'Get Started',
        description: 'Best for small businesses, startups who launch new product.',
        optionOne: '50 generates per month',
        optionTwo: 'Save Documents',
        optionThree: 'Data privacy',
        optionFour: 'Basic Support',
        url: 'https://swiftproduct.lemonsqueezy.com/checkout/buy/3fcb1b1c-0ecd-404b-bec1-7d9497517568?media=0&logo=0',
    },
    {
        name: 'Premium',
        price: '27.90',
        planLabel: 'Recommended',
        buttonLabel: 'Get Started',
        recommended: true,
        description: 'Best for any business who launch new product or create a lot of features.',
        optionOne: '100 generates per month',
        optionTwo: 'Save Documents',
        optionThree: 'Data privacy',
        optionFour: 'Premium Support 24/7',
        url: 'https://swiftproduct.lemonsqueezy.com/checkout/buy/1900a1ca-87bc-4fcd-867e-6d9af433708a?media=0&logo=0'
    },
    {
        name: 'Enterprise',
        price: '109.90',
        planLabel: 'Best Value',
        buttonLabel: 'Get Started',
        description: 'Best for large business who create a lot of documentations for new features and products.',
        optionOne: '500 generates per month',
        optionTwo: 'Save Documents',
        optionThree: 'Data privacy',
        optionFour: '24/7 live support',
        url: 'https://swiftproduct.lemonsqueezy.com/checkout/buy/d65dcb47-715e-4134-8cd8-825f5ef77df2?media=0&logo=0'
    }
];

const PricingPlans = () => {
    return (
        <div className='PricingPlansArea'>
            <h2>Pricing</h2>
            <h1>Choose Your Plan</h1>
            <p style={{ textAlign: 'center' }}>From Basic to Enterprise, our pricing plans guarantee your documents will be so fabulous, even unicorns will be jealous!</p>
            <p style={{ textAlign: 'center' }}>Note! When paying, please indicate your registered email.</p>
            <div className="pricing-container">
                {plans.map(plan => (
                    <div className={`card ${plan.recommended ? 'recommended' : ''}`} key={plan.name}>
                        <div className='priceCardHead'>
                            <div className='priceCardTitle'>
                                <h2>{plan.name}</h2>
                                <span>{plan.planLabel}</span>
                            </div>
                            <div className={`priceTag ${plan.recommended ? 'priceTagRec' : ''}`} key={plan.name}>
                                <h1>${plan.price}</h1>
                                <span>per Month</span>
                            </div>
                        </div>
                        <div className='divine'></div>
                        <p>{plan.description}</p>
                        <a href={plan.url} class="lemonsqueezy-button">
                            <button>{plan.buttonLabel}</button>
                        </a>
                        <div className="features">
                            <h3>What's included:</h3>
                            <ul>
                                <li><div className='planOptions'><IoIosCheckmarkCircle style={{ fontSize: '20px' }} /> {plan.optionOne}</div></li>
                                <li><div className='planOptions'><IoIosCheckmarkCircle style={{ fontSize: '20px' }} /> {plan.optionTwo}</div></li>
                                <li><div className='planOptions'><IoIosCheckmarkCircle style={{ fontSize: '20px' }} /> {plan.optionThree}</div></li>
                                <li><div className='planOptions'><IoIosCheckmarkCircle style={{ fontSize: '20px' }} /> {plan.optionFour}</div></li>
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PricingPlans;
