import React, { useState, useEffect } from 'react';
import { auth, signInWithGoogle, signOut, db } from './firebase';
import { doc, getDoc } from 'firebase/firestore';
import Fuse from 'fuse.js';
import './TopPage.css';
import { IoIosMenu, IoIosClose} from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import EmptyProfile from './images/profile-empty.png';

const TopPage = () => {
    const [user, setUser] = useState(null);
    const [email, setEmail] = useState('');
    const [menuOpen, setMenuOpen] = useState(false);
    const [statusDays, setStatusDays] = useState(null);
    const [status, setStatus] = useState(null);
    const navigate = useNavigate(); // Use navigate hook

    const DEFAULT_PROFILE_PICTURE = EmptyProfile;

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    // Modified signOut function
    const handleSignOut = async () => {
        await signOut();
        // Use the navigate function to redirect to '/'
        navigate('/');
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async user => {
            setUser(user);
            if (user) {
                // Fetch user document from Firestore
                const userDocRef = doc(db, "Users", user.uid);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    setEmail(userDoc.data().email); // Set the email from Firestore
                    setStatusDays(userDoc.data().statusDays);
                    setStatus(userDoc.data().status);
                }
            } else {
                setEmail(''); // Clear email when user is signed out
            }
        });
        return unsubscribe;
    }, []);

    return (
        <div className="top-page">
            <div className='leftSideTop'>
                
            </div>
            <div className='rightSideTop'>
                <div>
                    {email ? email : 'Not Logged In'}
                </div>
                <div>
                {user ? (
                     <>
                     <div className='dropmenu-area' onClick={toggleMenu}>
                     <img src={user.photoURL || DEFAULT_PROFILE_PICTURE} alt={user.displayName} className="profile-picture" />
                         {menuOpen ? <IoIosClose style={{fontSize: '30px'}}/> : <IoIosMenu style={{fontSize: '30px'}}/>}
                     </div>
                     <div className={`dropdown-menu ${menuOpen ? 'menu-open' : ''}`}>
                         {/* <button className='menuButtons'>Settings</button> */}
                         <p>Status: <span style={{textTransform: 'capitalize'}}>{status}</span></p>
                         <p>Status expires: {statusDays} Days</p>
                         <button className='menuButtons' onClick={handleSignOut}>Sign Out</button>
                     </div>
                 </>
                ) : (
                    <button onClick={signInWithGoogle} className='submitButton'>Login</button>
                )}
                </div>
            </div>
        </div>
    );
};

export default TopPage;
