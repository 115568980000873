
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { db, auth } from './firebase'; // Adjust the path if necessary
import { updateDoc, doc, collection, addDoc } from 'firebase/firestore';
import { fetchUserData } from './utils'; // Adjust the path if necessary
import Collapsible from './Collapsible';
import FAQ from './Faq';
import generateImage from './images/generate.png';
import YourPreviousDocsComponent from './YourPreviousDocsComponent'; // Import this component
import Lottie from 'lottie-react';
import rocketAnimation from './images/RocketAnim1.json';

const TextToDoc = () => {
  const [userInput, setUserInput] = useState('');
  const [options, setOptions] = useState({
    UserStories: false,
    AcceptanceCriteria: false,
    ProductDescription: false
  });
  const [apiResponses, setApiResponses] = useState([]);
  const [error, setError] = useState('');
  const [generateAmount, setGenerateAmount] = useState(0); // New state variable for generateAmount
  const [userId, setUserId] = useState(null); // Assuming you have a way to get the current user's ID
  const [currentView, setCurrentView] = useState('generate'); // New state variable for current view
  const isAnyOptionSelected = options.UserStories || options.AcceptanceCriteria || options.ProductDescription;
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);

  const showGenerateView = () => setCurrentView('generate');
  const showPreviousDocsView = () => setCurrentView('previousDocs');

  const isActive = (viewName) => currentView === viewName;

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUserId(user.uid);
        fetchUserData(user.uid, db, setGenerateAmount);
      }
    });
    return () => unsubscribe(); // Clean up the subscription
  }, []);

  const storeResponseInHistory = async (userId, responses) => {
    const historyRef = collection(db, 'History', userId, 'responses');

    // Create a new date object
    const now = new Date();
    // Format the date and time
    const dateTimeFormatted = `${now.getDate().toString().padStart(2, '0')}/${(now.getMonth() + 1).toString().padStart(2, '0')}/${now.getFullYear()} ${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}`;

    try {
      // Use fixed keys for each type of response
      await addDoc(historyRef, {
        firstResponse: responses.find(response => response.type === 'User Stories')?.content || '',
        secondResponse: responses.find(response => response.type === 'Acceptance Criteria')?.content || '',
        thirdResponse: responses.find(response => response.type === 'Product Description')?.content || '',
        dateTime: dateTimeFormatted,
        type: "Text-to-Doc"
      });
    } catch (error) {
      console.error('Error storing response in history:', error);
    }
  };


  const decreaseGenerateAmountInFirestore = async (userId, newAmount) => {
    const userDocRef = doc(db, 'Users', userId);
    try {
      await updateDoc(userDocRef, {
        generateAmount: newAmount
      });
    } catch (error) {
      console.error('Error updating generateAmount in Firestore:', error);
    }
  };

  const LoadingBar = ({ progress, rocketImage }) => {
    // Implement your LoadingBar component
  };

  const onInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const toggleOption = (option) => {
    setOptions(prevOptions => ({
      ...prevOptions,
      [option]: !prevOptions[option]
    }));
  };

  const onOptionChange = (option) => {
    setOptions(prevOptions => ({
      ...prevOptions,
      [option]: !prevOptions[option]
    }));
  };

  const generateTextWithGPT35 = async (content) => {
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo-16k',
          messages: [
            {
              role: 'system',
              content: 'You are a Senior Product Manager',
            },
            {
              role: 'user',
              content: content,
            },
          ],
          max_tokens: 5000,
        },
        {
          headers: {
            'Authorization': 'Bearer sk-proj-pfmh7ZUq9lqj2nCsvtrKT3BlbkFJeoMLbke33p5lOIY0h4VQ', // Replace with your API Key
          },
        }
      );
      return response.data.choices[0]?.message?.content || 'No response';
    } catch (error) {
      console.error('API Error:', error);
      return 'No response';
    }
  };


  const onSubmit = async () => {

    setIsLoading(true);
    setLoadingProgress(0);

    try {
      const responses = [];
      for (const option in options) {
        if (options[option]) {
          const content = `Provide me ${option.replace(/([A-Z])/g, ' $1').trim()} for ${userInput}`;
          const responseContent = await generateTextWithGPT35(content);
          responses.push({
            type: option.replace(/([A-Z])/g, ' $1').trim(),
            content: responseContent,
          });
        }
      }
      setApiResponses(responses);

      await storeResponseInHistory(userId, responses);

      // Decrease generateAmount by 1 after all requests complete
      const newAmount = generateAmount - 1;
      setGenerateAmount(newAmount);

      // Update the generateAmount in Firestore
      await decreaseGenerateAmountInFirestore(userId, newAmount);
    } catch (error) {
      console.error('API Error:', error);
      setError('API Error: ' + error.message);
    }
    setIsLoading(false);
  };


  return (
    <div className='imageToDocArea'>
      <h1>Text to Document</h1>
      <div className='imageLeftRightArea'>
        <div className='leftImageDoc'>
          <h3 className='titleFieldsFirst'>About product:</h3>
          <p className='descFields'>Enter product description, instructions, or requirements.</p>
          <textarea value={userInput} onChange={onInputChange} placeholder="Enter your message here..." className='textAreaTextToDoc' />
          <div>
            <h3 className='titleFields'>Options</h3>
            <p className='descFields'>Each option will be included in the document.</p>
            <div className='optionsArea'>
              <button
                className={`toggleButton ${options.UserStories ? 'active' : 'inactive'}`}
                onClick={() => toggleOption('UserStories')}
              >
                User Stories
              </button>
              <button
                className={`toggleButton ${options.AcceptanceCriteria ? 'active' : 'inactive'}`}
                onClick={() => toggleOption('AcceptanceCriteria')}
              >
                Acceptance Criteria
              </button>
              <button
                className={`toggleButton ${options.ProductDescription ? 'active' : 'inactive'}`}
                onClick={() => toggleOption('ProductDescription')}
              >
                Product Description
              </button>
            </div>
          </div>
          <div className='submitArea'>
            <button
              onClick={onSubmit}
              className='submitButton'
              disabled={!isAnyOptionSelected}
            >
              Generate
            </button>
            <p>You have {generateAmount} Credits to generate</p>
          </div>
        </div>
        <div className='rightImageDoc'>


          <div>
            <div className='switchWorkSpace'>
              <div
                className="switchButton"
                onClick={showGenerateView}
                style={isActive('generate') ? { backgroundColor: '#7B3AED', color: '#fff', borderRadius: '5px', padding: '10px 20px' } : {}}
              >
                Generate
              </div>
              <div
                className="switchButton"
                onClick={showPreviousDocsView}
                style={isActive('previousDocs') ? { backgroundColor: '#7B3AED', color: '#fff', borderRadius: '5px', padding: '10px 20px' } : {}}
              >
                Previous Docs
              </div>
            </div>
            {isLoading ? (
              <div className="spinner-container">
                <Lottie
                  animationData={rocketAnimation}
                  loop={true}
                  autoplay={true}
                  style={{ width: '300px' }}
                />
              </div>
            ) : (
              <>
                {currentView === 'generate' && (
                  <div>
                    {apiResponses.length > 0 ? apiResponses.map((response, index) => (
                      <Collapsible
                        key={index}
                        title={`${response.type}:`}
                        content={response.content}
                      // onContentChange and onCopy handlers as needed
                      />
                    )) : (
                      <div className="empty-message">
                        <img src={generateImage} alt="Generate" />
                        <div>You have not recently generated any docs</div>
                        <div>Use left nav to generate new request</div>
                      </div>
                    )}
                  </div>
                )}
                {currentView === 'previousDocs' && (
                  <YourPreviousDocsComponent />
                )}
              </>
            )}

          </div>

          <div>
            <FAQ />
          </div>
        </div>

      </div>
    </div>
  );
};

export default TextToDoc;