import { doc, getDoc } from 'firebase/firestore';
import axios from 'axios';

export const encodeImage = async (imageFile) => {
    try {
      const base64Image = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.replace(/^data:.+;base64,/, ''));
        reader.onerror = reject;
        reader.readAsDataURL(imageFile);
      });
      return base64Image;
    } catch (error) {
      console.error('Error encoding image:', error);
      return null;
    }
  };


  export const fetchUserData = async (userId, db, setGenerateAmount) => {
    try {
      const docRef = doc(db, 'Users', userId);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        setGenerateAmount(docSnap.data().generateAmount); // This should correctly update the state
      } else {
        console.log('No such document for user ID:', userId);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };
  

  export const generateTextWithGPT35 = async (prompt, api_key, setTextCallback = null) => {
    try {
        const response = await axios.post(
            'https://api.openai.com/v1/chat/completions',
            {
                model: 'gpt-3.5-turbo-16k',
                messages: [
                    {
                        role: 'system',
                        content: 'You are a Senior Product Manager',
                    },
                    {
                        role: 'user',
                        content: `${prompt}`,
                    },
                ],
                max_tokens: 1500,
            },
            {
                headers: {
                    Authorization: `Bearer ${api_key}`,
                },
            }
        );

        const generatedText = response.data.choices[0]?.message?.content;

        // Only set the text if the callback function is provided
        if (setTextCallback && generatedText) {
            setTextCallback(generatedText);
        }

        // Return the response for further processing
        return response;
    } catch (error) {
        console.error('Error generating text:', error);
        return null; // Return null in case of an error
    }
};


export const parseUserStories = (text) => {
  if (!text) return '';

  // Regex to split the text at a new line starting with a number followed by a period
  const regex = /\n(?=\d+\.\s)/;

  // Split the text using the regex
  const stories = text.split(regex);

  // Join the stories with line breaks
  return stories.map(story => story.trim()).join('<br /><br />');
};

  export const parseAcceptanceCriteria = (text) => {
    if (!text) return '';
    return text.split(/\s(?=\d\.)/).join('<br /><br />');
  };

  export const parseDetailedDescription = (text, sentencesPerParagraph = 3) => {
    if (!text) return '';
    const sentences = text.match(/[^\.!\?]+[\.!\?]+/g) || [];
    let paragraphs = [];
    for (let i = 0; i < sentences.length; i += sentencesPerParagraph) {
      paragraphs.push(sentences.slice(i, i + sentencesPerParagraph).join(' '));
    }
    return paragraphs.join('<br /><br />');
  };
  