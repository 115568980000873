import React from 'react';
import './Contact.css';

const Contact = () => {
    return (
        <div className='ContactUs'>
            <h1>Get in touch</h1>
            <h2>Feel free to ask your questions or seek support anytime!</h2>
            <form action="https://api.web3forms.com/submit" method="POST">
                <input type="hidden" name="access_key" value="21dcc3aa-711a-4617-84ce-9413956e2eee" />
                <label>Name</label>
                <input type="text" name="name" required />
                <label>Email</label>
                <input type="email" name="email" required />
                <label>Your message</label>
                <textarea name="message" required></textarea>
                <div className="h-captcha" data-captcha="true"></div>
                <button type="submit" className='submitEmail'>Submit Form</button>
            </form>
        </div>
    );
}

export default Contact;
