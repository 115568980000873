import React, { useState, useEffect } from 'react';
import { db, auth } from './firebase';
import { doc, getDoc } from "firebase/firestore";
import './UpgradeBlock.css';
import { IoMdPricetag } from 'react-icons/io'; // Import for Free plan
import { MdOutlineAssistant } from 'react-icons/md'; // Example icon for Basic plan
import { FaGem } from 'react-icons/fa'; // Example icon for Premium plan
import { AiOutlineStar } from 'react-icons/ai'; // Example icon for Enterprise plan

const UpgradeBlock = () => {
    const [userStatus, setUserStatus] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                try {
                    const userDocRef = doc(db, "Users", user.uid);
                    const userDoc = await getDoc(userDocRef);
                    if (userDoc.exists()) {
                        setUserStatus(userDoc.data().status);
                    } else {
                        console.log("No such document!");
                    }
                } catch (error) {
                    console.error("Error fetching user data:", error);
                }
            }
            setLoading(false);
        });
        return () => unsubscribe();
    }, []);

    const renderIcon = (status) => {
        switch (status) {
            case 'free':
                return <IoMdPricetag style={{ fontSize: '35px' }} />;
            case 'basic':
                return <MdOutlineAssistant style={{ fontSize: '35px' }} />;
            case 'premium':
                return <FaGem style={{ fontSize: '35px' }} />;
            case 'enterprise':
                return <AiOutlineStar style={{ fontSize: '35px' }} />;
            default:
                return null;
        }
    };

    return (
        <div className='statusLabel'>
            {!loading ? (
                userStatus ? (
                    <div className={`status-${userStatus}`}>
                        {renderIcon(userStatus)}
                        <div className='currentPlanText'>
                            <span>Current plan:</span>
                            <p>{userStatus.toUpperCase()}</p>
                        </div>
                    </div>
                ) : (
                    <div>User status not found.</div>
                )
            ) : (
                <div>Loading your status...</div>
            )}
        </div>
    );
};

export default UpgradeBlock;
