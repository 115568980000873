import { useState, useEffect } from 'react';
import { auth, db } from './firebase'; // Adjust the path if necessary
import { getDoc, doc } from 'firebase/firestore';

export const useUserData = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      if (user) {
        const userDoc = await getDoc(doc(db, 'Users', user.uid));
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }
      }
    });
    return unsubscribe;
  }, []);

  return userData;
};
