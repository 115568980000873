import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, signInWithPopup, TwitterAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc, updateDoc, serverTimestamp, collection, getDocs } from "firebase/firestore"; // Import updateDoc here

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAKxMNwOjuMdTg6QI0rzciH0ILOEzrKXJg",
    authDomain: "quickproduct-ef277.firebaseapp.com",
    projectId: "quickproduct-ef277",
    storageBucket: "quickproduct-ef277.appspot.com",
    messagingSenderId: "816849037623",
    appId: "1:816849037623:web:ebfa7b90d4db5836b392b2",
    measurementId: "G-6SX3KT6W4Q"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const twitterProvider = new TwitterAuthProvider();

const signInWithTwitter = async () => {
  try {
    const result = await signInWithPopup(auth, twitterProvider);
    await createOrUpdateUserDocument(result.user);
    return result.user;
  } catch (error) {
    console.error("Error signing in with Twitter", error);
  }
};

const signUpWithEmailPassword = async (email, password) => {
  try {
    const result = await createUserWithEmailAndPassword(auth, email, password);
    await createOrUpdateUserDocument(result.user);
    return result.user;
  } catch (error) {
    console.error("Error signing up with email/password", error);
  }
};

const signInWithEmailPassword = async (email, password) => {
  try {
    const result = await signInWithEmailAndPassword(auth, email, password);
    await createOrUpdateUserDocument(result.user);
    return result.user;
  } catch (error) {
    console.error("Error signing in with email/password", error);
  }
};

const createOrUpdateUserDocument = async (user, newStatus = 'free') => {
  try {
    const userDocRef = doc(db, "Users", user.uid);
    const docSnap = await getDoc(userDocRef);

    let generateAmount = 5; // Default for 'free'
    let statusDays = 0; // Default for 'free'
    const today = new Date();

    if (newStatus === 'basic') {
      generateAmount = 50;
      statusDays = 30;
    } else if (newStatus === 'premium') {
      generateAmount = 100;
      statusDays = 30;
    } else if (newStatus === 'enterprise') {
      generateAmount = 500;
      statusDays = 30;
    }

    if (!docSnap.exists()) {
      // If user document doesn't exist, create a new document
      await setDoc(userDocRef, {
        email: user.email,
        generateAmount,
        status: newStatus,
        statusDays,
        generateRefreshDate: today, // Set generateRefreshDate to today
        registrationDate: serverTimestamp(),
        lastLoginDate: serverTimestamp()
      });
    } else {
      // Existing user, update last login date, check and update status if necessary
      const userData = docSnap.data();

      // Check if status needs to be downgraded
      if (userData.status !== 'free' && new Date() > userData.statusExpiryDate.toDate()) {
        // Downgrade to free status
        generateAmount = 5;
        statusDays = 0;
        newStatus = 'free';
      }

      // Check if generateAmount needs to be refreshed
      const refreshDate = userData.generateRefreshDate.toDate();
      if (today >= refreshDate) {
        let newGenerateRefreshDate;
      
        // Determine newGenerateRefreshDate based on status
        if (userData.status !== 'free') {
          newGenerateRefreshDate = userData.statusExpiryDate.toDate();
        } else {
          newGenerateRefreshDate = addDays(today, 30);
        }
      
        await updateDoc(userDocRef, {
          lastLoginDate: serverTimestamp(),
          generateAmount,
          status: newStatus,
          statusDays,
          generateRefreshDate: newGenerateRefreshDate, // Update generateRefreshDate with the calculated value
          statusExpiryDate: addDays(today, statusDays) // Calculate new expiry date
        });
      } else {
        await updateDoc(userDocRef, {
          lastLoginDate: serverTimestamp()
        }, { merge: true });
      }      
    }
  } catch (error) {
    console.error("Error creating/updating user document", error);
  }
};

const updateUserStatus = async (userId, newStatus) => {
  const userDocRef = doc(db, "Users", userId);
  const today = new Date();
  let generateAmount, statusDays;

  switch (newStatus) {
    case 'basic':
      generateAmount = 50;
      statusDays = 30;
      break;
    case 'premium':
      generateAmount = 100;
      statusDays = 30;
      break;
    case 'enterprise':
      generateAmount = 500;
      statusDays = 30;
      break;
    default:
      generateAmount = 5; // default for 'free'
      statusDays = 0;
      newStatus = 'free'; // Ensuring default to free if unrecognized status
  }

  try {
    await updateDoc(userDocRef, {
      status: newStatus,
      generateAmount,
      statusDays,
      lastLoginDate: serverTimestamp(),
      generateRefreshDate: getRefreshDate(),
      statusExpiryDate: addDays(today, statusDays)
    });
  } catch (error) {
    console.error("Error updating user status", error);
  }
};


// Helper function to add days to a date
const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    await createOrUpdateUserDocument(result.user);
    return result.user;
  } catch (error) {
    console.error("Error signing in with Google", error);
  }
};

const signOut = async () => {
  try {
    await auth.signOut();
  } catch (error) {
    console.error("Error signing out", error);
  }
};

const getRefreshDate = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0); // Set to next day at 00:00 AM
  return tomorrow;
};

const checkStatusExpiryDate = async () => {
  try {
    const usersCol = collection(db, 'Users');
    const querySnapshot = await getDocs(usersCol);
    
    const today = new Date();

    for (const docSnap of querySnapshot.docs) {
      const userData = docSnap.data();

      if (userData.statusExpiryDate && today < userData.statusExpiryDate.toDate()) {
        // Calculate the difference in days between statusExpiryDate and today
        const timeDifference = userData.statusExpiryDate.toDate() - today;
        const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

        console.log(`User ${docSnap.id}:`);
        console.log(`- Old statusDays: ${userData.statusDays}`);
        console.log(`- New statusDays: ${daysDifference}`);

        // Update the statusDays field
        await updateDoc(doc(usersCol, docSnap.id), { statusDays: daysDifference });
      }
    }
  } catch (error) {
    console.error('Error checking status expiry date', error);
  }
};

const updateGenerateAmount = async () => {
  try {
    const usersCol = collection(db, 'Users');
    const querySnapshot = await getDocs(usersCol);

    const today = new Date();

    for (const docSnap of querySnapshot.docs) {
      const userData = docSnap.data();
      console.log(`User data for ${docSnap.id}:`, userData);

      // Check if generateAmount needs to be refreshed
      const refreshDate = userData.generateRefreshDate.toDate();
      if (today >= refreshDate) {
        let generateAmount;

        // Determine generateAmount based on status
        switch (userData.status) {
          case 'basic':
            generateAmount = 50;
            break;
          case 'premium':
            generateAmount = 100;
            break;
          case 'enterprise':
            generateAmount = 500;
            break;
          default:
            generateAmount = 5; // default for 'free'
        }
        console.log(`Calculated generateAmount: ${generateAmount}`);

        // Update generateAmount and generateRefreshDate
        await updateDoc(doc(usersCol, docSnap.id), {
          generateAmount,
          generateRefreshDate: getRefreshDate(),
        });
      }
    }
  } catch (error) {
    console.error('Error updating generateAmount', error);
  }
};


const updateGenerateRefreshDate = async () => {
  try {
    const usersCol = collection(db, 'Users');
    const querySnapshot = await getDocs(usersCol);

    const today = new Date();

    for (const docSnap of querySnapshot.docs) {
      const userData = docSnap.data();

      let newGenerateRefreshDate;

      // Check user status and calculate newGenerateRefreshDate accordingly
      if (userData.status !== 'free') {
        newGenerateRefreshDate = userData.statusExpiryDate.toDate();
      } else {
        newGenerateRefreshDate = addDays(userData.generateRefreshDate.toDate(), 30);
      }

      // Update generateRefreshDate
      await updateDoc(doc(usersCol, docSnap.id), {
        generateRefreshDate: newGenerateRefreshDate,
      });
    }
  } catch (error) {
    console.error('Error updating generateRefreshDate', error);
  }
};

export { auth, signInWithGoogle, signOut, db, checkStatusExpiryDate, updateGenerateAmount, updateGenerateRefreshDate, signUpWithEmailPassword, signInWithEmailPassword, signInWithTwitter };
