import React, { useState } from 'react';
import {
    signInWithGoogle,
    signInWithTwitter,
    signUpWithEmailPassword,
    signInWithEmailPassword,
    db
} from './firebase';
import './Auth.css';
import HeroImg from './images/hero-img.png';
import GoogleLogo from './images/google-icon-logo.svg';
import XLogo from './images/X.svg';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const Auth = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState(''); // New state for re-password
    const [isSignUp, setIsSignUp] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const auth = getAuth();
    const navigate = useNavigate();

    const handleSignInWithGoogle = async () => {
        try {
            await signInWithGoogle();
            // Check if the user is logged in
            const user = auth.currentUser;
            if (user) {
                navigate('/design-to-doc');
                // Redirect or update UI as needed after successful sign-in
            }
        } catch (error) {
            // Handle any errors during sign-in
            console.error('Error signing in with Google:', error);
        }
    };

    const handleSignInWithTwitter = async () => {
        try {
            await signInWithTwitter();
            // Check if the user is logged in
            const user = auth.currentUser;
            if (user) {
                navigate('/design-to-doc');
                // Redirect or update UI as needed after successful sign-in
            }
        } catch (error) {
            // Handle any errors during sign-in
            console.error('Error signing in with Twitter:', error);
        }
    };
    

    const getRefreshDate = () => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0);
        return tomorrow;
    };

    const handleSignUpWithEmailPassword = async () => {
        // Check if passwords match
        if (password !== rePassword) {
            setErrorMessage('Re-password incorrect');
            return;
        }

        try {
            const result = await createUserWithEmailAndPassword(auth, email, password);
            const userData = {
                email: result.user.email,
                generateAmount: 5,
                status: 'free',
                statusDays: 0,
                generateRefreshDate: getRefreshDate(),
            };
            await updateUserData(result.user.uid, userData);
            console.log('Sign up success');
            const user = auth.currentUser;
            if (user) {
                navigate('/design-to-doc');
                // Redirect or update UI as needed after successful sign-in
            }
        } catch (error) {
            setErrorMessage('Email is already used, please use another one'); // Handle specific errors if needed
        }
    };

    const updateUserData = async (userId, data) => {
        try {
            const userDocRef = doc(db, 'Users', userId);
            const docSnap = await getDoc(userDocRef);

            if (!docSnap.exists()) {
                await setDoc(userDocRef, {
                    ...data,
                    registrationDate: serverTimestamp(),
                    lastLoginDate: serverTimestamp(),
                });
            } else {
                await updateDoc(userDocRef, {
                    ...data,
                    lastLoginDate: serverTimestamp(),
                });
            }
        } catch (error) {
            console.error('Error creating/updating user document', error);
        }
    };

    const handleSignInWithEmailPassword = async () => {
        await signInWithEmailPassword(email, password);
        const user = auth.currentUser;
        if (user) {
            navigate('/design-to-doc');
            // Redirect or update UI as needed after successful sign-in
        }
    };

    const backNavigate = async () => {
        navigate('/');
    };

    const toggleAuthType = () => {
        setIsSignUp(!isSignUp);
    };

    return (
        <div className='AuthPage'>
            <div className='authArea'>
                <div className='AuthLeftSide'>
                    <div className='backButton' onClick={backNavigate}>
                        <IoIosArrowBack /> Back
                    </div>
                    <div className='signToggler'>
                        <div className={`signToggleButtonLeft ${isSignUp ? 'active' : ''}`} onClick={() => setIsSignUp(true)}>
                            <h1>Sign up</h1>
                        </div>
                        <div className={`signToggleButtonRight ${!isSignUp ? 'active' : ''}`} onClick={() => setIsSignUp(false)}>
                            <h1>Sign in</h1>
                        </div>
                    </div>
                    <div className='signUpWith'>
                        <div className='lineSign'></div>
                        {isSignUp ? <h2>Sign up with</h2> : <h2>Sign in with</h2>}
                    </div>
                    <div className='socialButtons'>
                        <div className='socialButton' onClick={handleSignInWithGoogle}>
                            <img src={GoogleLogo} className='logoIcon' alt='Login to AI Assistance with Google'/> <span>Continue with Google</span>
                        </div>
                        <div className='socialButton' onClick={handleSignInWithTwitter}>
                            <img src={XLogo} className='logoIcon' alt='Login to AI Assistance with Twitter'/> <span>Continue with X</span>
                        </div>
                    </div>
                    <h2 style={{ textAlign: 'center' }}>or</h2>
                    <form className='formAuth'>
                        <label>Email</label>
                        <input type='email' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                        <label>Password</label>
                        <input type='password' placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} />
                        {isSignUp && (
                            <>
                                <label>Re-Password</label>
                                <input type='password' placeholder='Re-Password' value={rePassword} onChange={(e) => setRePassword(e.target.value)} />
                            </>
                        )}
                    </form>
                    {errorMessage && (
                        <div className='errorMessage'>
                            <p>{errorMessage}</p>
                        </div>
                    )}
                    <button className='enterButton' onClick={isSignUp ? handleSignUpWithEmailPassword : handleSignInWithEmailPassword}>
                        <IoIosArrowForward style={{ fontSize: '35px' }} />
                    </button>
                    <span className='signInLine'>
                        {isSignUp
                            ? "Already have an account? "
                            : "Don't have an account? "}
                        <a onClick={toggleAuthType} className='SignInButton'>
                            {isSignUp ? "Sign in" : "Sign up"}
                        </a>
                    </span>
                </div>
                <div className='AuthRightSide'>
                    <img src={HeroImg} className='authImg' alt='Login to AI Assistance which help to create you a product'/>
                </div>
            </div>
        </div>
    );
};

export default Auth;
