import React, { useState, useEffect } from 'react';
import { db, auth } from './firebase';
import { collection, getDocs, query, doc, updateDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import './WorkBoard.css';
import { Editor, EditorState, convertToRaw, ContentState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';

const YourPreviousDocsComponent = () => {
    const [userHistory, setUserHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    // Additional state for editing content
    const [editableContent, setEditableContent] = useState('');
    const [currentHistoryId, setCurrentHistoryId] = useState('');
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [isEditMode, setIsEditMode] = useState(false);



    const formatResponse = (content, type) => {
        if (typeof content !== 'string') return 'No content available';

        switch (type) {
            case 'userStories':
                return content.split('As a user').slice(1).map(story => `<p>As a user${story.trim()}</p>`).join('');
            case 'acceptanceCriteria':
                return content.split(/\d+\./).slice(1).map(criteria => `<p>${criteria.trim()}</p>`).join('');
            case 'productDescription':
                return `<p>${content.replace(/(\.)/g, '.$1<br><br>')}</p>`;
            default:
                return `<p>${content}</p>`;
        }
    };

    const openModal = (firstResponse, secondResponse, thirdResponse, historyItem) => {
        let combinedContent = '';

        if (firstResponse && firstResponse.trim() && firstResponse !== 'No response') {
            combinedContent += `<h2>User Stories</h2>${formatResponse(firstResponse, 'userStories')}`;
        }
        if (secondResponse && secondResponse.trim() && secondResponse !== 'No response') {
            if (combinedContent) combinedContent += '<br>'; // Add a break if there's already content
            combinedContent += `<h2>Acceptance Criteria</h2>${formatResponse(secondResponse, 'acceptanceCriteria')}`;
        }
        if (thirdResponse && thirdResponse.trim() && thirdResponse !== 'No response') {
            if (combinedContent) combinedContent += '<br>'; // Add a break if there's already content
            combinedContent += `<h2>Product Description</h2>${formatResponse(thirdResponse, 'productDescription')}`;
        }

        setModalContent(combinedContent); // Keep the HTML format for display

        // Initialize editorState with modal content
        const contentState = stateFromHTML(combinedContent);
        setEditorState(EditorState.createWithContent(contentState));

        setIsModalOpen(true);

        const plainTextContent = htmlToPlainText(combinedContent);
        setEditableContent(plainTextContent); // Set converted plain text for editing
        setCurrentHistoryId(historyItem.id);
        setIsModalOpen(true);
    };

    const handleContentChange = (e) => {
        setEditableContent(e.target.value);
    };

    const saveContentToFirestore = async () => {
        if (!currentHistoryId) {
            console.error('No history item selected for saving');
            return;
        }

        try {
            const contentState = editorState.getCurrentContent();
            const htmlContent = stateToHTML(contentState);

            const historyDocRef = doc(db, 'History', auth.currentUser.uid, 'responses', currentHistoryId);
            await updateDoc(historyDocRef, {
                yourFieldToUpdate: htmlContent  // Make sure this matches the field name in Firestore
            });

            console.log('Content updated successfully in Firestore');

            setModalContent(htmlContent);

            // Update the userHistory state
            const updatedUserHistory = userHistory.map(item => {
                if (item.id === currentHistoryId) {
                    return { ...item, yourFieldToUpdate: htmlContent };
                }
                return item;
            });
            setUserHistory(updatedUserHistory);

            setIsEditMode(false);
        } catch (error) {
            console.error('Error updating content in Firestore:', error);
        }
    };


    const closeModal = () => {
        setIsModalOpen(false);
        setIsEditMode(false); // Reset edit mode when closing the modal
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                fetchHistory(user.uid);
            } else {
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);

    const fetchHistory = async (userId) => {
        setLoading(true);
        try {
            const historyQuery = query(collection(db, 'History', userId, 'responses'));
            const querySnapshot = await getDocs(historyQuery);
            const historyData = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
    
                // Check if the 'dateTime' field exists and is in the correct format
                if (data.dateTime) {
                    // Assuming 'dateTime' is in the format 'DD/MM/YYYY HH:mm'
                    const dateTimeParts = data.dateTime.split(/[/ :]/);
                    if (dateTimeParts.length === 5) {
                        const dateObject = new Date(dateTimeParts[2], dateTimeParts[1] - 1, dateTimeParts[0], dateTimeParts[3], dateTimeParts[4]);
                        historyData.push({ id: doc.id, ...data, dateObject });
                    }
                }
            });
    
            // Sort the data by 'dateObject' in descending order and slice to get the first 12
            const sortedAndLimitedData = historyData
                .sort((a, b) => b.dateObject - a.dateObject)
                .slice(0, 12);
    
            setUserHistory(sortedAndLimitedData);
        } catch (error) {
            console.error('Error fetching history:', error);
        } finally {
            setLoading(false);
        }
    };
    

    const copyToClipboard = async () => {
        try {
            const plainTextContent = htmlToPlainText(editableContent);
            await navigator.clipboard.writeText(plainTextContent);
            alert('Content copied to clipboard!'); // Or use a more sophisticated notification
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    const htmlToPlainText = (htmlString) => {
        let text = htmlString.replace(/<br\s*\/?>/gi, '\n'); // Replaces <br> with newlines
        text = text.replace(/<\/p>/gi, '\n'); // Converts end of paragraphs to newlines
        text = text.replace(/<[^>]+>/g, ''); // Removes any remaining HTML tags
        return text;
    };


    const renderResponseContent = (response) => {
        if (!response) {
            console.log('Response is undefined or null');
            return 'No content available';
        }

        // Check if the response is a string (directly the content)
        if (typeof response === 'string') {
            // Format the response if it's a list
            return formatResponse(response);
        }

        // Check if the response has 'choices'
        if (response.choices && response.choices.length > 0 && response.choices[0].message) {
            return formatResponse(response.choices[0].message.content);
        } else {
            console.log('Unexpected response structure:', response);
            return 'No content available';
        }
    };
    const enableEditMode = () => {
        setIsEditMode(true);
    };

    const Modal = ({ isOpen, onClose }) => {
        if (!isOpen) return null;

        return (
            <div className="modal-overlay" onClick={onClose}>
                <div className="modal-content" onClick={e => e.stopPropagation()}>
                    <div className='buttonHistoryArea'>
                        <button onClick={copyToClipboard} className='buttonHistoryModal'>Copy</button>
                        <button onClick={closeModal} className='buttonHistoryModal'>Close</button>
                    </div>
                    {isEditMode ? (
                        <Editor editorState={editorState} onChange={setEditorState} />
                    ) : (
                        <div dangerouslySetInnerHTML={{ __html: modalContent }} />
                    )}
                </div>
            </div>
        );
    };


    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            {userHistory.length > 0 ? (
                <div className='HistoryContent'>
                    {userHistory.map((historyItem, index) => (
                        <div key={historyItem.id} className='historyItem'>
                            <div onClick={() => openModal(historyItem.firstResponse, historyItem.secondResponse, historyItem.thirdResponse, historyItem)} className='productCard'>
                                <p>{historyItem.type}</p>
                                <p>{historyItem.title || `Product Card #${index + 1}`}</p>
                                <p>{historyItem.dateTime}</p>
                            </div>

                            {/* Optionally show a preview or other details here */}
                        </div>
                    ))}
                </div>
            ) : (
                <div>No history found.</div>
            )}

            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} content={modalContent} />
        </div>
    );
};

export default YourPreviousDocsComponent;
