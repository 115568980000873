import React, { useState } from 'react';
import { Editor, EditorState, convertToRaw, ContentState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';

const Collapsible = ({ title, content, onContentChange, onCopy }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    // Initialize editorState with content if it's HTML, otherwise with plain text
    const [editorState, setEditorState] = useState(() => {
      return content ? EditorState.createWithContent(stateFromHTML(content)) : EditorState.createEmpty();
  });
  

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = () => {
      setIsEditing(false);
      const contentState = editorState.getCurrentContent();
      const htmlContent = stateToHTML(contentState);
      onContentChange(htmlContent); // This should update the parent component's state
  };
  

    const handleCopy = () => {
        const rawContent = convertToRaw(editorState.getCurrentContent());
        onCopy(JSON.stringify(rawContent));
    };

    return (
        <div className="additionalTextArea">
            <h3 onClick={() => setIsOpen(!isOpen)} style={{ cursor: 'pointer' }}>{title}</h3>
            {isOpen && (
                <div className='responseArea'>
                    <div className='responseButtonArea'>
                        <button onClick={isEditing ? handleSave : handleEdit} className='buttonHistoryModal'>
                            {isEditing ? 'Save' : 'Edit'}
                        </button>
                        {!isEditing && (
                            <button onClick={handleCopy} className='buttonHistoryModal'>Copy</button>
                        )}
                    </div>
                    {isEditing ? (
                        <Editor editorState={editorState} onChange={setEditorState} />
                    ) : (
                        <div dangerouslySetInnerHTML={{ __html: content }} className='fieldResponseText'></div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Collapsible;
